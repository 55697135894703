// Here you can add other styles
.TableContainerFull {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $Border-color;
  height: $Table-default-height;
  box-sizing: border-box;
  padding: 5px 1rem;
  margin: 10px 0px;
  color: $Table-default-color;
}

.demo_notification {
  text-align: justify;
  line-height: 25px;
  margin: 0px 0px;

  span {
    color: #23b7e5;
    font-weight: bold;
    text-decoration: underline;
  }
}

.colonie {
  background-color: #f4f4f4;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
    letter-spacing: 1px;
  }
}

.syncSubTitle {
  font-size: 19px;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.moneySpan {
  color: green;

  &::before {
    content: '$';
  }
}

.loginBackground {
  background: rgb(0, 80, 177);
}

//iconos

.categorieIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  width: 170px;
  height: 170px;
  border: 1px solid $gray;
  margin: auto;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
}

.modalIcon {
  width: 100px;
  cursor: pointer;
  border-radius: 100%;

  &:hover {
    border: 1px solid #23b7e5;
  }
}

.columnModal {
  justify-content: center;
  display: flex;
}

.day {
  width: '100%';
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 900;
  background-color: #c43232;
  color: white;
}

.selected {
  background-color: #229222;
}

//Dashboard

.container-fluid {
  height: 100%;
}

//Scores

.scoresContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.scoreContainer {
  padding: 10px;
  width: 45%;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.scoreTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.commentBox {
  padding: 10px;
  text-align: justify;
  text-overflow: clip;
  font-variant: small-caps;
  font-style: italic;
  font-weight: 300;
}

.starsBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.stars {
  i {
    color: rgb(55, 67, 177);
  }
}

.filterContainer {
  width: 30%;
  margin-bottom: 20px;
}

@media screen and (max-width: 990px) {
  .scoresContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .scoreContainer {
    width: 100%;
  }

  .filterContainer {
    width: 100%;
    margin-bottom: 20px;
  }
}
