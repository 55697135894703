// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import '../scss/style.scss';

.sidebar .nav-link:hover {
  i {
    color: #20a8d8 !important;
  }
}

html body {
  font-family: 'poppins' !important;
}

.view {
  .preloader {
    height: 100vh !important;
    overflow: hidden;
    z-index: 9999;
    background-color: #23b7e5;
    transition: opacity .65s;

    p {
      color: white;
      font-size: 50px;
      font-weight: 600;
    }

    small {
      position: relative;
      left: -10px;
      bottom: -9px;
      font-size: 25px;
    }
  }

}
